import React from 'react';
import {Link} from 'react-router-dom';

export default class ProjectThree extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($(".project-three__carousel").length) {
            $(".project-three__carousel").owlCarousel({
              loop: true,
              margin: 10,
              nav: false,
              smartSpeed: 500,
              autoHeight: false,
              autoplay: true,
              dots: false,
              autoplayTimeout: 6000,
              navText: [
                '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
                '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
              ],
              responsive: {
                0: {
                  items: 1,
                },
                600: {
                  items: 1,
                },
                750: {
                  items: 2,
                },
                1100: {
                  items: 3,
                },
                1350: {
                  items: 4,
                },
              },
            });
          }

          if ($(".img-popup").length) {
            var groups = {};
            $(".img-popup").each(function () {
              var id = parseInt($(this).attr("data-group"), 10);
        
              if (!groups[id]) {
                groups[id] = [];
              }
        
              groups[id].push(this);
            });
        
            $.each(groups, function () {
              $(this).magnificPopup({
                type: "image",
                closeOnContentClick: true,
                closeBtnInside: false,
                gallery: {
                  enabled: true,
                },
              });
            });
          }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="project-three">
                    
                    <div className="container-fluid">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>LATEST PROJECT</h6> <span className="right"></span>
                            </div>
                            {/* <h2 className="sec-title__title" style={{color:'#001659'}}>Our latest completed and <br /> running projects.</h2> */}
                            <h2 className="sec-title__title" style={{color:'#001659'}}>Our running project SWPL (South West Port Limited)</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="project-three__carousel owl-carousel owl-theme">
                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/SouthWestPortLimited.png"} alt="South West Port Limited" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/SouthWestPortLimited.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                {/* /portfolio-details */}
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/1.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/1.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/2.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/2.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/3.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/3.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/4.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/4.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/5.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/5.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/6.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/6.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/7.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/7.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/8.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/8.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/9.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/9.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/10.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/10.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/11.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/11.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/12.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/12.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                            {/* <div className="col-xl-12">
                                <div className="project-three-more-project-btn text-center">
                                    <div className="btn-box">
                                        <Link href="/portfolio" className="thm-btn" data-text="View All Portfolio +">View All project +</Link>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    <br/>
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                {/* <span className="left"></span> */}
                                {/* <h6>LATEST PROJECT</h6> <span className="right"></span> */}
                            </div>
                            {/* <h2 className="sec-title__title" style={{color:'#001659'}}>Our latest completed and <br /> running projects.</h2> */}
                            <h2 className="sec-title__title" style={{color:'#001659'}}>Our running project (9th Berth Jetty Extension)</h2>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="project-three__carousel owl-carousel owl-theme">
                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/9th Berth Jetty Extension.png"} alt="9th Berth Jetty Extension" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/9th Berth Jetty Extension.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                {/* /portfolio-details */}
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/1.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/1.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/2.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/2.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/3.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/3.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/4.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/4.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/5.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/5.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/6.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/6.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/7.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/7.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/8.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/8.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/9.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/9.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/10.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/9th Berth Jetty Extension/10.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>9th Berth Jetty Extension</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/11.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/11.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/South West Port Limited/12.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/South West Port Limited/12.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>South West Port Limited</Link></h3>
                                            </div>
                                        </div>
                                    </div> */}
                                
                                </div>
                            </div>

                            {/* <div className="col-xl-12">
                                <div className="project-three-more-project-btn text-center">
                                    <div className="btn-box">
                                        <Link href="/portfolio" className="thm-btn" data-text="View All Portfolio +">View All project +</Link>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <br />
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                {/* <span className="left"></span> */}
                                {/* <h6>LATEST PROJECT</h6> <span className="right"></span> */}
                            </div>
                            {/* <h2 className="sec-title__title" style={{color:'#001659'}}>Our latest completed and <br /> running projects.</h2> */}
                            <h2 className="sec-title__title" style={{color:'#001659'}}>Our running project (SGIPL)</h2>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="project-three__carousel owl-carousel owl-theme">
                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/SGIPL/1.png"} alt="SGIPL" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/SGIPL/1.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                {/* /portfolio-details */}
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>SGIPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/SGIPL/2.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/SGIPL/2.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>SGIPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/SGIPL/3.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/SGIPL/3.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>SGIPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/SGIPL/4.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/SGIPL/4.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>SGIPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/SGIPL/5.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/SGIPL/5.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>SGIPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/SGIPL/6.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/SGIPL/6.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>SGIPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/SGIPL/7.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/SGIPL/7.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>SGIPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                   
                                
                                </div>
                            </div>

                            {/* <div className="col-xl-12">
                                <div className="project-three-more-project-btn text-center">
                                    <div className="btn-box">
                                        <Link href="/portfolio" className="thm-btn" data-text="View All Portfolio +">View All project +</Link>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <br />
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                {/* <span className="left"></span> */}
                                {/* <h6>LATEST PROJECT</h6> <span className="right"></span> */}
                            </div>
                            {/* <h2 className="sec-title__title" style={{color:'#001659'}}>Our latest completed and <br /> running projects.</h2> */}
                            <h2 className="sec-title__title" style={{color:'#001659'}}>Our running project (VCTPL)</h2>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="project-three__carousel owl-carousel owl-theme">
                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/VCTPL/1.png"} alt="VCTPL" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/VCTPL/1.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                {/* /portfolio-details */}
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>VCTPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/VCTPL/2.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/VCTPL/2.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>VCTPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/VCTPL/3.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/VCTPL/3.png"}><span
                                                        className="icon-plus-sign"></span></a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>VCTPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}

                                    {/* Start Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/VCTPL/4.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/VCTPL/4.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>VCTPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Project Three Single */}
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/VCTPL/5.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/VCTPL/5.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>VCTPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/VCTPL/6.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/VCTPL/6.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>VCTPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-three__single">
                                        <div className="project-three__single-img">
                                            <img src={publicUrl+"assets/images/MIP/VCTPL/7.png"} alt="#" />
                                            <div className="icon-box">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/VCTPL/7.png"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                            <div className="content-box">
                                                <p>Megha Infra Projects</p>
                                                <h3><Link to={process.env.PUBLIC_URL + ``}>VCTPL</Link></h3>
                                            </div>
                                        </div>
                                    </div>
                                   
                                
                                </div>
                            </div>

                            {/* <div className="col-xl-12">
                                <div className="project-three-more-project-btn text-center">
                                    <div className="btn-box">
                                        <Link href="/portfolio" className="thm-btn" data-text="View All Portfolio +">View All project +</Link>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </section>
            </>
        )
    }
}