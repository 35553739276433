import React from 'react';

export default class ContactMap extends React.Component {
    render(){
        return (
            <>
                <div className="contact-page-google-map">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <iframe
                                    title="Address"
                                    src='https://maps.google.com/maps?q=californiaFlat No. 301 Nuelite Apartments Isukathota Vsp 530022&t=&z=10&ie=UTF8&iwloc=&output=embed'
                                    className="contact-page-google-map__one" allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



