import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ContactMap from "../components/contact/ContactMap";
import ContactForm from "../components/contact/ContactForm";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from "../common/footer/FooterOne";

const Careers = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Careers"
                currentPage="Careers" 
            />
         <div class="d-flex justify-content-center align-items-center m-5">
           <img src={publicUrl+"assets/images/careers.jpg"} alt="" class='' />
           
        </div>
        <div class="d-flex justify-content-center align-items-center m-5">
           <p>
          Share your resume to <strong> <a href='mailto:meghainfraprojects@gmail.com'>meghainfraprojects@gmail.com</a></strong>
           </p>
           </div>

            
            
            <FooterOne />
        </>
    )
}

export default Careers;