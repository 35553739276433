import React from 'react';
import {Link} from 'react-router-dom';

export default class AboutFour extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
              type: "iframe",
              mainClass: "mfp-fade",
              removalDelay: 160,
              preloader: true,
        
              fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <div className="about-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="about-three__img">
                                    <div className="inner">
                                        <img src={publicUrl+"assets/images/logo.jpg"} alt="#" />
                                        <div className="about-three__img-video wow zoomIn" data-wow-delay="100ms">
                                            {/* <a href="" className="video-popup">
                                                <div className="about-three__img-video-icon">
                                                    <span className="fa fa-play"></span>
                                                    <i className="ripple"></i>
                                                </div>
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="about-three__content">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6> ABOUT US </h6> <span className="right"></span>
                                        </div>
                                        
                                        <h2 className="sec-title__title">Welcome to <br/> Megha infra Projects</h2>
                                    </div>

                                    <div className="about-three__content-inner">
                                        <div className="text1">
                                            <p>At megha infra, we take pride in being a premier construction company dedicated to excellence, innovation, and reliability. With 10+ years of experience in the industry, we have built a strong reputation for delivering top-notch construction services to our valued clients.</p>
                                        </div>
                                        {/* <div className="text2">
                                            <h4>It is a long established fact that a reader will be distracted by the readable
                                                content </h4>
                                        </div> */}
                                        {/* <ul className="about-three__content-list">
                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-tick"></span>
                                                </div>
                                                <div className="text-box">
                                                    <p> Expert Team</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-tick"></span>
                                                </div>
                                                <div className="text-box">
                                                    <p> Innovation and Technology</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-tick"></span>
                                                </div>
                                                <div className="text-box">
                                                    <p>Safety and Sustainability</p>
                                                </div>
                                            </li>
                                            
                                        </ul> */}
                                        <div className="about-three__content-btn">
                                        <Link to={process.env.PUBLIC_URL + `assets/images/MIP/pdfs/MIP Profile-main.pdf`} className="thm-btn" data-text="MIP Profile Pdf" href="assets/images/MIP/pdfs/MIP Profile-main.pdf" target="_blank">Link to PDF</Link>
                                                {/* Discover More </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}