import React from 'react';
import {Link} from 'react-router-dom';

export default class ProjectFour extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($(".img-popup").length) {
            var groups = {};
            $(".img-popup").each(function () {
              var id = parseInt($(this).attr("data-group"), 10);
        
              if (!groups[id]) {
                groups[id] = [];
              }
        
              groups[id].push(this);
            });
        
            $.each(groups, function () {
              $(this).magnificPopup({
                type: "image",
                closeOnContentClick: true,
                closeBtnInside: false,
                gallery: {
                  enabled: true,
                },
              });
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="project-one-sec project-one-sec--three">
                   <div className="container-fluid">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Gallery</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title" style={{color:"#001659"}}>CSF-VCT</h2>
                        </div>
                        <div className="row">
                         

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/1.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/1.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/4.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/4.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/11.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/11.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/10.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/10.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/12.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/12.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/15.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/15.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/3.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/3.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/5.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/5.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/6.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/6.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/8.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/8.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/9.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/9.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/CSF-VCT/14.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/CSF-VCT/14.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>CSF-VCT</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                    <div className="sec-title text-center">
                                    <div className="sec-title__tagline">
                                        <span className="left"></span>
                                        <h6>Gallery</h6> <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title" style={{color:"#001659"}}>GOA</h2>
                                    </div>




                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/4.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/4.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/11.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/11.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/12.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/12.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/6.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/6.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/13.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/13.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/15.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/15.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/16.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/16.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/22.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/22.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/3.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/3.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/1.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/1.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/7.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/7.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/8.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/8.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/9.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/9.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/19.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/19.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/25.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/25.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/2.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/2.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/5.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/5.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/14.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/14.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/17.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/17.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/18.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/18.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/GOA/23.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/GOA/23.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>GOA</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            



                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Gallery</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title" style={{color:"#001659"}}>VCTPL-YARD</h2>
                        </div>

                            


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/1.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/1.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/2.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/2.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/3.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/3.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/4.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/4.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/5.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/5.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/6.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/6.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/7.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/7.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/8.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/8.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/9.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/9.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/10.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/10.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/11.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/11.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/12.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/12.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/13.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/13.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/14.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/14.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/15.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/15.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/16.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/16.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/17.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/17.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/18.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/18.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/19.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/19.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/24.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/24.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/25.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/25.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/20.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/20.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/21.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/21.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/23.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/VCTPL-YARD/23.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL-YARD</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


   

                            <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Gallery</h6> <span className="right"></span>
                                </div>
                                <h2 className="sec-title__title" style={{color:"#001659"}}>TUTICORIN</h2>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/1.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/1.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/2.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/2.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/5.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/5.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/6.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/6.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/7.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/7.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/8.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/8.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/9.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/9.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/10.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/10.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/17.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/17.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/16.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/16.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/13.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/13.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/19.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/19.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/20.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/20.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/21.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/21.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/22.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/22.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/23.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/23.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/27.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/27.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/28.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/28.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/29.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/29.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/30.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/30.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/31.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/31.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/33.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/33.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/39.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/39.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/48.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/48.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/37.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/37.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/38.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/38.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/40.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/40.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/41.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/41.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/43.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/43.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/45.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/45.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/3.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/3.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/11.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/11.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/14.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/14.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/15.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/15.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/18.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/18.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                  

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/25.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/25.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/26.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/26.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/32.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/32.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/34.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/34.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/24.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/24.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/35.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/35.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/TUTICORIN/36.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/TUTICORIN/36.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>TUTICORIN</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Gallery</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title" style={{color:"#001659"}}>Saint-Gobain</h2>
                        </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/1.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/1.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/4.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/4.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/5.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/5.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/16.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/16.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/17.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/17.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/18.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/18.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/38.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/38.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/40.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/40.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/41.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/41.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/2.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/2.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/3.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/3.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/6.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/6.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/7.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/7.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/8.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/8.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/9.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/9.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/10.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/10.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/11.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/11.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/12.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/12.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/13.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/13.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/14.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/14.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/15.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/15.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/19.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/19.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/20.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/20.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                    

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/23.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/23.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/24.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/24.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/25.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/25.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/26.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/26.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/27.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/27.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/28.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/28.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/30.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/30.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/31.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/31.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/32.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/32.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/33.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/33.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/34.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/34.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/35.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/35.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/MIPNEW/saint-gobain/36.jpeg"} alt="" />
                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIPNEW/saint-gobain/36.jpeg"}>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint-Gobain</Link></h2>
                                            <p>Megha Infra Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>




                         






                        </div>
                    </div>
                </section>
            </>
        )
    }
}